<template>
  <slot></slot>
</template>

<script setup lang="ts">
import 'sass/print/index.scss';

import {useSeoMeta} from '#imports';

useSeoMeta({
  robots: 'noindex,nofollow',
  // TODO original value, error?
  googlebot: 'noindex,nofollow',
  viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
});
</script>
